import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {Nomenclature} from '@matchsource/models/nomenclature';
import {Injectable} from '@angular/core';
import {GendersApiService} from '@matchsource/api/genders';
import {GetGenders} from './genders.actions';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';

export type GendersStateModel = EntitiesStateModel<Nomenclature>;

@State<GendersStateModel>({
  name: 'genders',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class GendersState {
  constructor(private readonly gendersApi: GendersApiService) {}

  @Selector([GendersState])
  static genders(state: GendersStateModel) {
    return state.entities;
  }

  @Selector([GendersState])
  static inProgressOrLoaded(state: GendersStateModel) {
    return state.loaded || state.loading;
  }

  @Action(GetGenders)
  getAll(ctx: StateContext<GendersStateModel>) {
    const state = ctx.getState();

    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));

    return this.gendersApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(genders =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', genders), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
